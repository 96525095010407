// Gatsby supports TypeScript natively!
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy - Lawn Chair Sauvignon Blanc" />    
    <div className="relative z-30 -mt-1">
      <div className="gPadding w-full relative max-w-1440 mx-auto text-black">
        <div className="privacy-copy relative my-32">
          
          <h2 className="text-center mb-8">Privacy Policy</h2>
          <p>Anecwines Corporation and its brands, affiliates, subsidiaries, companies and/or their related brands (collectively referred to here as "we," "our,") created this Privacy Policy to inform our website users, club members, event registrants/participants, newsletter/information recipients, promotion, contest, sweepstakes or campaign participants, online community participants, those who correspond with us via SMS (text message), those who purchase or inquire about our products and services and others (collectively referred to as "you") about our practices regarding collection, use and disclosure of personally identifiable information ("personal data") that you provide to us or we collect from you. All references to our "website" in this Privacy Policy include any of our affiliated microsites linked to our website as well as any of our affiliated mobile applications.</p>
          <p>This Privacy Policy does not apply to any of our other websites that contain a separate privacy policy, but only to sites that specifically link to this Privacy Policy.</p>
          <p>While our website or other materials may include links or references to non-Winery websites, platforms, materials or entities, this Privacy Policy does not apply to any data that may be collected through those non-Winery sites, platforms, materials or entities, unless otherwise indicated. This includes any websites of affiliated entities.</p>
          <p><strong>Information Collection and Use</strong><br />We may receive personal data (including, without limitation, age, birthdate, address, email address, phone number, Facebook, Twitter, Instagram, and LinkedIn (&ldquo;Social Media Sites&rdquo;) account information, and geographic location) from you both online and offline. Online, we may receive information through our website, an affiliated site (such as a promotional microsite linked to our site), a brand page on a community website (such as Facebook or the like) or through similar online avenues, and via mobile phone applications or SMS (text message) correspondence. In many cases, such information will be collected directly from you, such as through a transaction or inquiry (including via a telephone call or SMS correspondence) or your submission of a form, registration or communication to us. Information may also be gathered from third-party sources (including without limitation, our third-party service providers (such as marketing and fulfillment companies), opt-in lists, publicly available data, consumer reporting agencies, other companies and referrals) and through your activities and interactions with us, including, without limitation, your online activity on our website. We may combine data collected from these disparate sources unless we tell you otherwise. Any of your personal data or other information gathered by one of our brands may be shared with and used by any of our other brands in accordance with this Privacy Policy.</p>
          <p>The types of personal data include the following, depending on your activities:</p>
          <ul>
          <li><strong>Contact information and Geographic Location,</strong>&nbsp;including name, title, street address (home, work, billing, shipping), postal code, country, telephone numbers (home, work, cell, etc.), email address (home, work), company name and other similar information, and your geographic location.</li>
          <li><strong>Personal/demographic information,</strong>&nbsp;including birthday/age/age group, gender, birth date, salary range, occupation, level of education, contest- or promotion-specific information (e.g., for wedding promotions, we ask for your wedding date, location, etc.).</li>
          <li><strong>Authentication information,</strong>&nbsp;including user IDs, log-in names, passwords, password reminders, copy of ID, social security number. (in addition to other personal data such as contact information, birth date).</li>
          <li><strong>Transactional and financial information,</strong>&nbsp;including products or services you purchase, inquire about or request, payment information, and debit/credit card information.</li>
          <li><strong>Preferences, views and comments,</strong>&nbsp;including beverage preferences and consumption frequency, subscriptions/communications preferences, feedback in response to surveys, language preference.</li>
          </ul>
          <p><strong>Participation information,</strong>&nbsp;including event attendance, sweepstakes/contest entries (including all information submitted on an entry such as clothing size), information provided when requesting or receiving discounts/coupons/rebates, registrations, information relating to use of online communities (for example social media IDs) and all other user submissions and content you provide.</p>
          <p>As you use our website, some information can be "passively" collected using various technologies, such as cookies, web beacons and navigational and location data collection (clickstream, log files, server logs). Your Internet browser automatically transmits some of this information, such as the URL of the website you just visited and the Internet Protocol (IP) address and browser version currently being used by your computer. Such technologies help us to keep track of your interactions with our website and provide you with a more customized experience.</p>
          <p>As is the case with many websites, our website may use cookies and other automated information collection means. Cookies are information about you from our website that is stored on your browser or hard drive. Cookies save you time because you do not need to manually re-enter the information stored on your hard drive. Cookies also help us optimize and upgrade our website by showing when and how users use our website. You can set your browser to notify you when a cookie is sent or refuse cookies altogether, but certain features of our website might not work without cookies.</p>
          <p>Our website may also use IP addresses for the same purposes identified above, as well as to analyze trends, administer our website, track users' movements and online activity and gather demographic information for aggregate use.</p>
          <p>We may use web beacons (also known as Internet tags or clear GIFs) on our website to access and set cookies and otherwise help us to better understand how users are moving through our website. Information provided by the web beacon includes the computer's IP address, the type of browser being used and the time that the web beacon was viewed. We may also use web beacons in emails and newsletters so that we know when such communications have been opened and to otherwise help us tailor our communications to individual users.</p>
          <p>We use personal data about customers to respond to your requests (including requests for newsletter subscriptions, materials, coupons/rebates/discounts and so on); to provide products or services that you have ordered; to process your order transactions and contact you regarding your order; to invite and help you to participate in online and offline events, activities and/or clubs; to allow you to post information and other content online; to improve our products, services and websites; to follow up with you after you have communicated with us or submitted information to us; to send promotional communications or offers (unless you have requested that we not send such communications); for marketing, research and legal purposes and other purposes (including call center recordings); to permit you to participate in and to otherwise administer sweepstakes, promotions, campaigns and contests; to allow you to contact another person; to address your interests and concerns; and as otherwise specified in this Privacy Policy and/or at the point of personal data collection.</p>
          <p>We use personal data about customers in contexts that include the following:</p>
          <ul>
          <li><strong>Conducting and following up on transactions.</strong>&nbsp;We may use personal information such as name, title, street address, phone number, email address, title, company name, payment information and debit/credit card information to conduct transactions with you, process your order, and contact you about your order.</li>
          <li><strong>Registration, such as for events, memberships, accounts (i.e., log-ins and passwords, etc.).</strong>&nbsp;You may be given an opportunity to register with us to become/be a member of a wine club; to receive promotional communications/offers; to attend an event (e.g., a winery tasting panel); to join/participate in an online community, blog, chatroom or other interactive areas or to otherwise provide user-generated content (such as posting favorite photos/videos/audio recordings, providing testimonials, sharing experiences about Winery products, etc.). Such activities may entail collection of name, title, contact information, birth date, user name and password, beverage preferences, experiences with such products and personal information/preferences related to the subject matter of the particular activity. In some cases, you will select a user ID and password when you register and you will use this to gain access to certain registration-restricted areas. Note that using our website as a registered user enables information that is passively collected as you navigate our website to be associated with personal data actively collected from you or your activities on our website.</li>
          <li><strong>Participation in online communities/provision of user-generated content.</strong>&nbsp;Our website may provide online community features and may allow you to submit user-generated content for posting on our website. You are responsible for any postings you make in any online community. Anything you make available as a participant in an online community or submit for posting by us may be made available for others to see.</li>
          <li><strong>To follow up with you after you have completed a form, business reply card or survey, whether online or offline.</strong>&nbsp;When you visit our tasting room or attend an event where we are present, you might fill in an optional form, telling us your name, title, email address, beverage consumption preferences, whether you attended past events, your postal code, comments, whether you would like us to contact you in the future and/or other information. Likewise, you might choose to participate in an online or offline survey (anonymously or with name/contact information) where you provide demographic information, your beverage consumption information and survey-specific preference data. We use the personal data you provide to improve our products, services and website content and otherwise to more effectively communicate with you.</li>
          <li><strong>To allow you to contact us with questions, comments or concerns and to address your communications.</strong>&nbsp;We provide a number of ways for you to contact us to ask questions, provide comments or share concerns. This may occur, for example, via our Consumer Engagement team (by calling a designated phone number), the "Contact Us" section on our website or via email or postal mail. Depending on the circumstances, we may collect your name, title, contact information, age group, product UPC number, message type, case number (if tied to a previous issue), comments and other types of data relevant to the specific situation (e.g. product involved, use of the product, any problems with the product and the like).</li>
          <li><strong>To provide materials you request.</strong>&nbsp;We provide a number of ways for you to request materials from us, such as newsletters, coupons, rebates, brochures, product literature and the like. We may collect and use contact name, title, contact information, age-indicative information, demographic information, preferences, transaction information (such as related to rebates) and other information relevant to responding to your request.</li>
          <li><strong>To permit your participation in contests, sweepstakes and other promotions.</strong>&nbsp;To participate in sweepstakes, contests, campaigns and other similar promotions, we often collect name, title, contact information, age-indicative information and gender, and/or your geographic location as well as other information related to the particular activity.</li>
          <li><strong>For marketing purposes (unless you have told us otherwise).</strong>&nbsp;We may use your contact information to tell you about other products and services (including those of our other brands, affiliates and companies) that we believe might be of interest. If at any time you do not want communications from us for promotional purposes, please let us know by indicating such preference (more information under the "It's Your Choice" section below).</li>
          </ul>
          <p>We may disclose personal data you provide to any of our other brands and to service providers that we use to support our business and operations (e.g., delivery services, financial institutions, fulfillment services, technical support, advertising, public relations, media and marketing services, sweepstakes, promotions, campaigns and contest-related services, call-in centers, e-commerce and other web-related services such as web hosting and web monitoring services and event-related services such as online and offline data capture) who have agreed to keep the information confidential and use it only to provide the applicable service(s). In some cases, we work with other companies who help to gather information from you or help us to communicate with you. For example, our mobile applications or SMS correspondence campaigns may be administered by a third party service provider.</p>
          <p>We may also disclose personal data to third parties (including, without limitation, governmental agencies) if required to do so by law, regulation or court order; to respond to governmental and/or law enforcement requests; to identify, contact or bring legal action against someone who may be causing injury to or interfering with our (or others') rights or property; to support any actual or threatened claim, defense or declaration in a case or before any jurisdictional and/or administrative authority, arbitration or mediation panel; or in connection with disciplinary actions/investigations. Likewise, we may disclose personal data to third parties in connection with the sale, assignment or other transfer of the business of our website or the sale, assignment, merger, reorganization or other transfer of any of our brands or companies.</p>
          <p>We (including any of our brands) may use your user-generated content you choose submit to us including by displaying it on our sites and including for our promotional purposes.</p>
          <p><strong>Security</strong><br />We take steps to protect personal data from loss, misuse and unauthorized access, disclosure, alteration or destruction, whether in transmission or storage including by pseudonymizing and/or encrypting data in certain cases. Please keep in mind, however, that there is no such thing as perfect security, and no Internet transmission is ever completely secure or error-free. Moreover, you are responsible for maintaining the confidentiality of any user name and password you use.</p>
          <p><strong>It's Your Choice: Contact Preferences and Opt-Out</strong><br />We want to keep in touch with you in ways that you find to be beneficial. If you don't want us to use your contact information to communicate with you for promotional purposes (such as to tell you about other products and services that might be of interest or to offer coupons/discounts), please tell us that when you provide your contact information. If you decide at a later time you do not want to receive promotional communications, you can communicate this to us by accessing your account via our site (if applicable) or by following the instructions in the communications that we may send you. You can also communicate with us through the methods described in the "How to Contact Us" section below. Keep in mind that these preferences regarding promotional contacts do not mean that we might not contact you for other reasons, such as those related to an order you placed, an inquiry you made, a newsletter to which you may have subscribed, a membership you undertook, an event for which you registered, or a legally required notice.</p>
          <p><strong>SMS Messaging Opt-Out</strong><br />If you have participated in our general SMS correspondence campaign, you may opt-out by texting "STOP" to the short code provided in that message. Text "HELP" to the code for help or call us at the Consumer Relations number below for more assistance. Message and data rates may apply.</p>
          <p><strong>Your California Privacy Rights</strong><br />California Do Not Track Notice: Because there are not yet common, industry accepted "do not track" standards and systems, our website does not respond to Do Not Track signals. In addition, we may allow third parties to collect personal data from your activity on our website, as described in the "Information Collection and Use" section above.</p>
          <p><strong>How To Contact Us for More Information</strong><br />If you have any questions about this Privacy Policy or our information practices, please contact us by emailing us using the contact information below.</p>
          <p><strong>Changes to This Privacy Policy</strong><br />This Privacy Policy may be revised from time to time for any reason. If this Privacy Policy changes, the revised policy will include a new effective date, and we will notify you of such changes by posting the revised policy on this page. Any such changes will automatically apply to all uses of our website and collection and use of your personal data by us, on or after such effective date (until such time as this Privacy Policy is subsequently revised). If you don't want us to use your personal data according to the new policy, don&rsquo;t submit your personal data or visit our website. Be sure to check the Privacy Policy whenever you submit personal data or use our website. The effective date of this Privacy Policy is&nbsp;<strong>June 1, 2021.</strong></p>
        </div>
      </div>
    </div>

   
  </Layout>
)

export default PrivacyPolicy
